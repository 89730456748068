@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --font-main: "Poppins", serif;

  --font-size-mid: 18px;
  --font-size-mid-small-screen: 16px;

  --margin-app: 0 5%;
  --margin-sides: 5%;
  --margin-section-sides: 5%;
  --margin-section-sides-half: 2.5%;

  --padding-section: 1rem;

  --bg-color-main: rgba(255, 218, 181, 1);
  --bg-color-div: rgba(255, 255, 255, 0.8);
  --bg-color-accent-100: rgba(255, 163, 69, 1);
  --bg-color-accent-050: rgba(255, 163, 69, 0.5);
  --bg-color-secondary-100: rgba(129, 115, 106, 1);
  --bg-color-secondary-050: rgba(217, 217, 217, 0.5);
}

body {
  background-color: var(--bg-color-main);
  font-family: var(--font-main);

  position: relative;

  min-height: 100vh;
}

/* common */
.disp-flex {
  display: flex;
}
.disp-block {
  display: block;
}
.disp-inline {
  display: inline;
}
.disp-none {
  display: none;
}

.hover-cursor:hover {
  cursor: pointer;
}

.bg-color-div {
  background-color: var(--bg-color-div);
}

.pd-btm-0 {
  padding-bottom: 0 !important;
}

.content-wrap {
  padding-bottom: 10vh;
}

/* header */
header {
  min-height: 8vh;

  overflow: hidden;
 
  margin: var(--margin-app);
}

.logo {
  border-radius: 10%;
}

.topnav {
  margin: 0.25rem 0;

  justify-content: space-evenly;
  align-items: center;
  
  li {
    margin: 0 0.75rem;
    
    display: inline-block;
  
    text-decoration: none;
    font-size: var(--font-size-mid);
  
    background-image: linear-gradient(#000 0 0);
    background-position: left bottom;
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition: background-size 0.5s;
    
  }
  
  li:hover,
  li.active {
    background-size: 100% 2px;
  }

  li.hidden {
    display: none !important;
  }

  .admin-tab {
    ul {
      padding: 0 0.75rem;
      margin-right: 2.5rem;

      display: none;
      position: absolute;
      z-index: 1;

      float: left;

      top: 4%;

      background-color: var(--bg-color-main);
      border-radius: 0 0 10% 10%;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }
    li {
      margin: 0.5rem 0 1rem 0;
      
      display: flex;
      flex-direction: column;
      float: left;
    }
  }
  .admin-tab:hover ul {
    display: flex;
    flex-direction: column;
  }
}


.topnav-hamburger {
  display: none;
}
.topnav-hamburger div {
  margin: 5px;

  height: 3px;
  width: 25px;

  background-color: black;

  transition: all 0.5s ease;
}
.active .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.active .line2 {
  opacity: 0;
}
.active .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}


/* footer */
footer {
  position: absolute;
  bottom: 0;
 
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 8vh;

  background-color: var(--bg-color-secondary-050);

  .footer-fb-icon {
    margin-left: var(--margin-section-sides);

    align-items: center;

    color: black;
  }
  .footer-fb-icon:hover {
    color: var(--bg-color-secondary-100);
  }
  .footer-copyright { 
    margin-right: var(--margin-section-sides);
  }
}

/* login */
.login-page-container {
  overflow: hidden;

  height: 82vh;

  display: flex;
  justify-content: center;
  align-items: center;
  
  .login-contents {
    padding: 2rem;

    width: min(356px, 60%);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: var(--bg-color-accent-050);
    box-shadow: 0px 6px 30px 0px var(--bg-color-secondary-100);
    border-radius: 10%;

    input, button {
      margin: 0.5rem 0;
      padding: 0.5rem;

      width: 300px;

      font-size: 18px;
    }

    button {
      width: 100%;

      background-color: var(--bg-color-secondary-100);
      border: 2px var(--bg-color-secondary-100) solid;
      border-radius: 10px;
      color: #ffffff; 
      transition: all 300ms;
    }
    button:hover {
      background-color: var(--bg-color-secondary-050);
      color: var(--bg-color-secondary-100);
    }
  }
}

/* main */
.main-container {
  margin: 0.5rem var(--margin-sides);

  display: flex;
  flex-direction: row;

  font-size: var(--font-size-mid);

}
.main-container-left {
  margin: 0 2.5% 0 0;

  flex: 70%
}
.main-container-right {
  margin: 0 0 0 2.5%;

  flex: 30%;
}

.main-image-container {
  margin-top: 1rem;
  position: relative;

  text-align: center;
}
.main-image {
  height: 200px;
  width: 100%;
}
.main-image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: white;

  font-size: 48px;
}

.main-section-container {
  margin: 0.5rem 0 1.25rem 0;
  padding: var(--padding-section) 0;

  border-radius: 10px;
  box-shadow: 0px 6px 10px 0px var(--bg-color-secondary-100);

  h3 {
    margin: 0;

    display: flex;
    justify-content: center;

    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* abou us */
.main-about-us-container {
  margin: 0.5rem var(--margin-section-sides);
}

.main-about-us-text {
  flex-direction: column;
  justify-content: space-between;

  h4 {
    margin: 0;
  }
}

/* news & updates */
.main-section-news-container {
  margin-top: 0.5rem;

  flex-direction: column;
}
.main-section-news-entries {
  padding: 1rem var(--margin-section-sides);

  transition: background 300ms;

  /* border-radius: 10px; */
}
.main-section-news-entries:hover {
  background-color: var(--bg-color-main);
  transition: background 700ms;
}
.main-section-news-entry-date,
.main-section-news-entry-title {
  flex: 50%;
}

/* current date */
.main-section-current-date {
  justify-content: center;
  align-items: center;

  margin: 0 0.5rem;
}

/* prayer timer */
.main-section-prayer-time {
  margin: 0.75rem var(--margin-section-sides);

  justify-content: space-between;
  font-size: var(--font-size-mid);

}

.main-section-prayer, 
.main-section-time {
  flex: 50%;
}

.jummah-time-container {
  padding: 0.5rem 0;

  background-color: var(--bg-color-secondary-100);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  align-items: center;
  flex-direction: column;

  color: #ffffff;
  font-weight: bold;
  font-size: var(--font-size-mid);

  div {
    margin: 0.25rem;
  }
}

/* donation btn */
.main-donation-btn {
  background-color: var(--bg-color-accent-100);
  border-radius: 10px;
  border: 2px var(--bg-color-accent-100) solid;
  box-shadow: 0px 6px 10px 0px var(--bg-color-secondary-100);

  height: 92px;

  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-weight: bold;
  font-size: 36px;

  transition: all 500ms ease-out;
}
.main-donation-btn:hover {
  background-color: var(--bg-color-accent-050);
}


/* common for pages */
.page-container {
  margin: 1rem var(--margin-sides);
  padding: 1rem 0;

  min-height: 70vh;

  box-shadow: 0px 6px 30px 0px var(--bg-color-secondary-100);
  border-radius: 10px;

  background-color: var(--bg-color-div);

  h3, h2 {
    margin: 0.5rem 1.5rem;
  }

  .news-entry-date {
    display: block;
    float: left;
  }
}
.page-container.news {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.page-content-container {
  padding: 0.75rem 1.5rem;
}

/* news & updates page */
.news-list-entries {
  padding: 1rem 1.5rem;
  transition: background 300ms;
}
.news-list-entry-date {
  flex: 40%;    
}
.news-list-entry-title {
  flex: 60%;
}
.news-list-entries:hover {
  background-color: var(--bg-color-main);
  transition: background 700ms;
}
.news-entry-date,
.news-entry-content,
.news-entry-image {
  padding: 0.25rem 1.5rem;
  
  white-space: pre-wrap;
}
.news-entry-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.news-entry-image {
  width: 100%;
  height: auto;
}

/* pagination */
.pagination-container {
  padding: 0;

  display: flex;
  justify-content: center;
  list-style-type: none;
  
  .pagination-item {
    padding: 0 0.75rem;
    margin: auto 0.25rem;

    display: flex;
    box-sizing: border-box;
    align-items: center;

    height: 2rem;
    /* min-width: 2rem; */

    text-align: center;
    
    border-radius: 1rem;
  }

  .dots:hover {
    cursor: default;
  }
  
  .pagination-item:hover {
    background-color: var(--bg-color-main);
    cursor: pointer;
  }
  
  .selected {
    background-color: var(--bg-color-accent-100);
    pointer-events: none;
  }

  .disabled {
    pointer-events: none;
    color: var(--bg-color-secondary-100);
  }

}

/* prayer times */
.prayer-timetable-container {
  flex-direction: column;
  align-items: center;
    
  .inner-contents {
    display: flex;
    flex-direction: column;
    align-items: center;    
  }
}
.prayer-timetable-month-arrow:hover {
  color: var(--bg-color-secondary-100);
}
.prayer-times-table {
  border-collapse: collapse;

  text-align: center;

  thead {
    background-color: var(--bg-color-secondary-100);
    color: #ffffff;
  }

  tbody tr {
    border-bottom: 1px var(--bg-color-secondary-100) solid;
  }

  th, td {
    padding: 0.25rem 0.5rem;
  }

  .prayer-time-today {
    background-color: var(--bg-color-main);
  }
}

/* contact */
.contact-section {
  margin-top: 1.25rem;

  .contact-title {
    align-items: center;

    h3 {
      margin-left: 0.25rem;
    }
  }
}

/* admin */
.admin-page-container {
  margin: 1rem var(--margin-sides);
 
  min-height: 70vh;

  border-radius: 10px;
  box-shadow: 0px 6px 30px 0px var(--bg-color-secondary-100);

  background-color: var(--bg-color-div);

  h2 {
    display: flex;
    justify-content: center;

    margin-top: 0;
  }
}
.admin-page-content {
  padding: 1rem 0;
}

.admin-main-section {
  padding: 3rem 1.5rem;

  justify-content: space-between;

  font-weight: bold;
  font-size: 24px;

  border-bottom: 2px var(--bg-color-accent-050) solid;

  transition: background 700ms;

  .next-arrow {
    font-size: 48px;
  }
}
.admin-main-section.top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.admin-main-section:hover {
  background-color: var(--bg-color-main);
}

.admin-page-content .buttons {
  justify-content: center;

  .button {
    margin: 0 1rem;

    display: flex;
    justify-content: center;
    align-items: center;    

    width: 100px;
    height: 35px;
    font-size: 18px;

    background-color: var(--bg-color-secondary-100);
    border: 2px var(--bg-color-secondary-100) solid;
    border-radius: 10px;
    color: #ffffff;

    transition: all 300ms;
  }
  .button:hover {
    background-color: var(--bg-color-secondary-050);
    color: var(--bg-color-secondary-100);
  }
}
.upd-prayer-times .buttons {
  .button {
    display: none;

  }
  .visible {
    display: flex;
    justify-content: center;
    align-items: center;    
  }
}
/* Update news list */
.upd-news-list {
  .news-entry-date,
  .news-entry-title,
  .buttons {
    flex:30%;

    display: flex;
    align-items: center;
  }

  .news-entries {
    padding: 0.75rem 0;
    transition: background 300ms;
  }  
  .news-entries:hover {
    background-color: var(--bg-color-main);
    transition: background 700ms;
  }

  .buttons .add {
    margin: 0.75rem 0;
  }
}
/* udpate news entry */
.upd-news-entry {
  .upd-entry-section {
    padding: 0 5%;

    display: flex;

    margin: 0.5rem 0;

    .section-title {
      flex: 20%;
    }
    .section-input {
      padding: 0.25rem 1%;
      flex: 40%;
      font-size: 16px;
      font: inherit;
    }
    input[type=checkbox] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(0.5); /* IE */
      -moz-transform: scale(0.5); /* FF */
      -webkit-transform: scale(0.5); /* Safari and Chrome */
      -o-transform: scale(0.5); /* Opera */
      transform: scale(0.5);
      padding: 10px;
    }
  }

  .buttons {
    margin: 1rem 0 0 0;
  }
}

/* update prayer times */
.upd-prayer-times {

  .prayer-times {
    padding: 0 10%;

    flex-direction: column;
    align-items: space-between;

    font-size: 18px;
  }
  .prayer {
    margin: 1rem 0;
  }

  .prayer-title {
    flex: 50%;
    display: flex;
    align-items: center;
  }
  .prayer-text-area {
    display: none;
  }
  .prayer-text-area.visible {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;    
  }
  
}

/* responsive*/
@media screen and (max-width: 760px) {
  body {
    overflow-x: hidden;
  }

  .topnav {
    justify-content: space-between;
  }

  .topnav ul {
    margin: 0;

    z-index: 1;
    position: fixed;

    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;

    top: 8vh;
    right: 0;
    height: 92vh;
    width: 50%;

    background-color: var(--bg-color-main);

    transform: translateX(100%);
    transition: transform 0.5s ease-in;
  }
  .topnav.visible ul {
    transform: translateX(0%);
  }

  .topnav-hamburger {
    display: block;
  }

  .main-container {
    flex-direction: column;

    /* font-size: var(--font-size-mid-small-screen); */
  }
  .main-container-left,
  .main-container-right,
  .main-sidebar-container {
    margin: 0;
  }
  
  .main-about-us-container {
    flex-direction: column;
  }
  .main-about-us-address {
    margin: 0.5rem 0;
  }

  /* prayer times */
  .prayer-times-table-container {
    width: auto;
  }
  .prayer-times-table {
    th, td {
      padding: 2px;
      font-size: 12px;
    }
  }

  /* admin */
  /* login */
  .login-page-container {
    .login-contents {
      input {
        width: auto;
      }
    }
  }

  /* udpate news list */
  .upd-news-list {
    .news-entries {
      flex-direction: column;
      align-items: center;
    }
  }

  /* udpate news entry */
  .upd-entry-section {
    flex-direction: column;

    padding: 0 3%;
  }
}

@media screen and (max-width: 300px) {
  .upd-prayer-times {
    .prayer {
      margin-bottom: 0;

      flex-direction: column;

      .prayer-title {
        margin-bottom: 0;
      }
    }
  }
}